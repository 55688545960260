<template>
  <div class="w-full space-y-24 px-5 pt-10 lg:max-w-sm lg:justify-self-center lg:px-0 lg:pt-0">
    <header class="flex flex-col items-center max-lg:space-y-20 ">
      <UiLogo class="px-10 lg:hidden" />
      <h1 class="font-dia text-6xl font-bold">
        Inloggen
      </h1>
    </header>
    <div class="mx-auto max-w-md space-y-24">
      <FormKitSchema
        submit-label="Sign in"
        v-bind="formSchema"
      />

      <div class="text-center">
        <NuxtLink
          class="text-primary underline"
          :to="{name: 'auth-request-password'}"
        >
          Wachtwoord vergeten?
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  auth: false,
  layout: 'auth',
  title: 'Inloggen',
});

const formSchema = useSofieLoginSchema();
</script>
